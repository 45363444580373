import React from "react";
import { createRef } from "react"
import "./Jukebox.scss"
import { JukeboxCallbacks, JukeboxState, Song } from "./JukeboxState";
import { JukeBoxBar } from "./JukeboxBar";
import { JukeboxDiplay } from "./JukeboxDisplay";
import { SERVER } from "../../routes/AppRoutes";

export class Jukebox extends React.Component {
    static JUKEBOX_ENDPOINT = SERVER + "api/music/music.php";
    static ALLOWED_STATUS: number[] = [200, 301];
    static NO_SONG: Song = {
        name: "No Song Found",
        uri: ""
    };
    // ========================================================================
    // Playlist management
    // ========================================================================
    /**
     * List of all the songs that can be played
     */
    static Songs: Song[];
    static getJukeboxSongsInfos(): Song[] {
        let json_data: Song[] = [Jukebox.NO_SONG];
        try {
            const req = new XMLHttpRequest();
            req.open("GET", Jukebox.JUKEBOX_ENDPOINT, false);
            //req.withCredentials = true;
            req.addEventListener("readystatechange", (e) => {
                if (req.readyState === 4 && Jukebox.ALLOWED_STATUS.includes(req.status)) {
                    json_data = JSON.parse(req.responseText);
                }
            }, false);
            req.send();
        } catch (e) {
            if (e instanceof DOMException)
                console.log(`DomException ${e.name} ${e.message}`);
        }
        return json_data;
    }
    static getShuffledPlaylist(): Song[] {
        if (Jukebox.Songs === undefined)
            Jukebox.Songs = Jukebox.getJukeboxSongsInfos();
        return [...Jukebox.Songs].sort(() => Math.random() - 0.5);
    }

    // ========================================================================
    // Class methods
    // ========================================================================
    static emptyState(): JukeboxState {
        let playlist: Song[] = Jukebox.getShuffledPlaylist();
        return {
            playlist: playlist,
            playlistIndex: 0,
            playing: false,
            duration: -1,
            time: 0
        }
    }

    // ========================================================================
    // Jukebox control
    // ========================================================================
    audioRef = createRef<HTMLAudioElement>();
    playNext() {
        let lState = { ...this.state };

        // Take next song (shuffle array if needed)
        lState.playlistIndex++;
        if (lState.playlistIndex === lState.playlist.length) {
            lState.playlist = Jukebox.getShuffledPlaylist();
            lState.playlistIndex = 0;
        }
        if (this.audioRef.current !== null) {
            this.audioRef.current.src = SERVER + lState.playlist[lState.playlistIndex].uri;
        }
        this.setState(lState);
    }

    play() {
        let lState = { ...this.state };
        if (lState.playlist[lState.playlistIndex].uri === "") {
            return;
        }
        lState.playing = !lState.playing;
        (lState.playing) ? this.audioRef.current?.play() : this.audioRef.current?.pause();

        this.setState(lState);
    }

    updateBar() {
        let lState = { ...this.state };
        lState.duration = this.audioRef.current?.duration ?? -1;
        lState.time = this.audioRef.current?.currentTime ?? 0;

        (lState.playing) ? this.audioRef.current?.play() : this.audioRef.current?.pause();

        this.setState(lState);
    }

    // ========================================================================
    // React Logic
    // ========================================================================
    internalClock: any = null;
    componentDidMount(): void {
        this.internalClock = setInterval(() => {
            this.updateBar();
        }, 200);
    }

    componentWillUnmount(): void {
        clearInterval(this.internalClock);
    }

    state: JukeboxState = Jukebox.emptyState();
    callbacks: JukeboxCallbacks = {
        play: () => this.play(),
        next: () => this.playNext()
    }

    render() {
        return (<div className="jukebox">
            <JukeboxDiplay state={this.state} callbacks={this.callbacks} />
            <audio
                autoPlay
                src={SERVER + this.state.playlist[this.state.playlistIndex].uri}
                onEnded={() => this.playNext()}
                ref={this.audioRef} />
            <JukeBoxBar state={this.state} />
        </div>)
    }
}