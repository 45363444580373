import logo from "./logo.png"
import './Header.scss';

import Countdown from "./Countdown";

function Header() {
    return (
        <>
            <div className="header-logo"><img src={logo} alt="Logo" /></div>
            <Countdown />
        </>
    );
}

export default Header