import { useEffect, useState } from "react";
import "./Countdown.scss"

function calculateChristmasCountdown(): { msg: string, cl: string } {
    let now = new Date();
    let currentMonth = (now.getMonth() + 1);
    let currentDay = now.getDate();
    let nextChristmasYear = now.getFullYear();
    if (currentMonth === 12 && currentDay > 25) {
        nextChristmasYear = nextChristmasYear + 1;
    }
    let nextChristmasDate = nextChristmasYear + '-12-25T00:00:00.000Z';
    let christmasDay = new Date(nextChristmasDate);
    let diffSeconds = Math.floor((christmasDay.getTime() - now.getTime()) / 1000);
    let days = 0;
    let hours = 0;
    let minutes = 0;

    if (currentMonth !== 12 || (currentMonth === 12 && currentDay !== 25)) {
        days = Math.floor(diffSeconds / (3600 * 24));
        diffSeconds -= days * 3600 * 24;
        hours = Math.floor(diffSeconds / 3600);
        diffSeconds -= hours * 3600;
        minutes = Math.floor(diffSeconds / 60);
    }

    return (currentMonth === 12 && currentDay === 25) ? { msg: `Joyeux Noël à tous !`, cl: "noel" } : { msg: `Plus que ${days} jours, ${hours} heures et ${minutes} minutes avant Noël !`, cl: "" };
}

function Countdown() {
    const [countdownState, setCountdownState] = useState({ msg: "", cl: "" });
    useEffect(() => {
        setCountdownState(calculateChristmasCountdown());
        const interval = setInterval(() => {
            setCountdownState(calculateChristmasCountdown());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (<div className={["header-countdown", countdownState.cl].join(" ")}>{countdownState.msg}</div>);
}

export default Countdown;