import { JukeboxButtons } from "./JukeboxButtons";
import { JukeboxDisc } from "./JukeboxDisc";
import { JukeboxCallbacks, JukeboxState } from "./JukeboxState";
import { JukeboxText } from "./JukeboxText";

export function JukeboxDiplay(props: { state: JukeboxState, callbacks: JukeboxCallbacks }) {
    return (<div className="jukebox--display">
        <div className="jukebox--info">
            <JukeboxDisc state={props.state} />
            <div className="jukebox--spacer" />
            <JukeboxText state={props.state} />
        </div>
        <JukeboxButtons state={props.state} callbacks={props.callbacks} />
    </div>)
}