import MenuLink from "./MenuLink";
import "./Menu.scss"
import { AppRoutes } from "../routes/AppRoutes";

function NavigationMenu() {
    return (
        <div className="nav-menu">
            {AppRoutes.map((category) => category.hasOwnProperty('nav') && (<MenuLink link_data={category} key={category.def_uri} />))}
        </div>
    )
}

export default NavigationMenu;