import { NavLink } from "react-router-dom";
import "./MenuLink.scss"
import { CategoryGroup } from "../routes/RouteType";

function MenuLink(props: { link_data: CategoryGroup }) {
    let data = props.link_data;
    if (data.nav === undefined)
        return (<></>);
    return (
        <NavLink
            to={(data.nav.uri !== undefined) ? data.nav.uri : data.def_uri}
            className={["nav-button", ((data.nav.disabled !== undefined && data.nav.disabled) ? "disabled" : "")].join(" ")}
            key={(data.nav.uri !== undefined) ? data.nav.uri : data.def_uri}>
            {data.nav.msg}
        </NavLink>
    )
}

export default MenuLink;