import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './common/Fonts.scss'
import './common/Scrollbars.scss'
import './common/Common.scss'
import NavigationPane from './navigation/NavigationPane';
import ContentPane from './content/ContentPane';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HashRouter>
      <NavigationPane />
      <ContentPane />
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
