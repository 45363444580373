import { JukeboxState } from "./JukeboxState"
import discIcon from "./disc-fill.svg"

export function JukeboxDisc(props: { state: JukeboxState }) {
    return (
        <div
            className={[
                "jukebox--disc",
                (props.state.playing) && "playing"
            ].join(" ")}>
            <img src={discIcon} alt="Jukebox disc" />
        </div>
    )
}