import { FilmInfo } from "../content/pages/films/FilmInfo";
import { TVProgram } from "../content/pages/films/TVProgram";
import { RouteDescriptor } from "./RouteType";

export const SERVER
    = (process.env.NODE_ENV === "production") ? ""
        : (process.env.NODE_ENV === "development") ? "http://localhost/noel/"
            : "http://localhost/noel/";

export const AppRoutes: RouteDescriptor = [
    // Films routes
    {
        def_uri: "",
        nav: {
            uri: "/",
            msg: "Films de Noël",
            disabled: false
        },
        header: {
            title: "Films de Noël",
            buttons: [{
                uri: "/",
                msg: "Programme TV",
            }, {
                uri: "/film/54",
                msg: "Rechercher",
                disabled: true
            }]
        },
        pages: [{
            uri: "*",
            child: () => (<TVProgram />)
        },
        {
            uri: "/film/:filmId",
            child: () => (<FilmInfo />)
        }]

    },
    // Other menu
    {
        def_uri: "/others",
        nav: {
            msg: "D'autres pages à venir !",
            disabled: true
        },
        pages: []
    },
];