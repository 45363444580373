import { useEffect, useState } from "react"
import { Planning } from "./Planning"
import axios from "axios";
import { SERVER } from "../../../routes/AppRoutes";
import { ContentSection } from "../../ContentSection";
import { ProgramHeader } from "./ProgramHeader";
import { FilmItem } from "./FilmItem";

function emptyPlanning(): Planning {
    return {
        NOW: [],
        TODAY: [],
        TOMORROW: []
    };
}

export function TVProgram() {
    const PLANNING_ENDPOINT = SERVER + "./api/planning/";

    const [planning, setPlanning] = useState(emptyPlanning());
    useEffect(() => {
        axios.get(PLANNING_ENDPOINT)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                try {
                    if (data.NOW === undefined)
                        data.NOW = [];
                    if (data.TODAY === undefined)
                        data.TODAY = [];
                    if (data.TOMORROW === undefined)
                        data.TOMORROW = [];
                    if (data.error !== undefined || data.jsonError !== undefined)
                        console.log(data);
                    setPlanning(data);
                }
                catch (e) {
                    console.log(e, data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return <ContentSection>
        {(planning.NOW.length !== 0) && (<ProgramHeader name="Maintenant" />)}
        {(planning.NOW.length !== 0) && planning.NOW.map((film) => (
            (film.fid !== undefined) &&
            <FilmItem
                film={film}
                key={["now", film.fid, film.start].join("-")}
            />))}
        {(planning.TODAY.length !== 0) && (<ProgramHeader name="Aujourd'hui" />)}
        {(planning.TODAY.length !== 0) && planning.TODAY.map((film) => (
            (film.fid !== undefined) &&
            <FilmItem
                film={film}
                key={["today", film.fid, film.start].join("-")}
            />))}
        {(planning.TOMORROW.length !== 0) && (<ProgramHeader name="Demain" />)}
        {(planning.TOMORROW.length !== 0) && planning.TOMORROW.map((film) => (
            (film.fid !== undefined) &&
            <FilmItem
                film={film}
                key={["tomorrow", film.fid, film.start].join("-")}
            />))}
        {(planning.NOW.length === 0) && (planning.TODAY.length === 0) && (planning.TOMORROW.length === 0) && (<ProgramHeader name="Pas de résultats" />)}
    </ContentSection>
}