import { NavLink } from "react-router-dom";
import { Program } from "./Planning";
import "./FilmItem.scss";

function formatTime(value: string): string {
    let times = value.split(" ")[1].split(":");
    return `${times[0]}h${times[1]}`;
}

/**
 * Return the number of days between two dates
 */
function dateDistancefrom(d1: Date, d2: Date) {
    return Math.floor(Math.abs(d2.getTime() - d1.getTime()) / (3600 * 24 * 1000));
}

function formatDay(value: string): string {
    console.log(dateDistancefrom(new Date(Date.parse(value)), new Date()));
    switch (dateDistancefrom(new Date(Date.parse(value)), new Date())) {
        case 0: return "Aujourd'hui";
        case 1: return "Demain";
        default: return "Un autre jour";
    }
}

function formatRating(value: number) {
    return (value == 0) ? (<></>) : <span>Magie de Noël : <span id="score">{"★".repeat(value)}{"☆".repeat(5 - value)}</span></span>;
}

export function FilmItem(props: { film: Program }) {
    return <NavLink
        to={"/film/" + props.film.fid}
        className='film disabled'
        style={{

        }}>
        <div className="film--item">
            <div className="film--container">
                <div className="film--top">
                    <div className="film--title">{props.film.title}</div>
                    <div className="film--note">{formatRating(props.film.rating)}</div>
                </div>
                <div className="film--desc">
                    {props.film.synopsis}
                </div>
            </div>
            <div className="film--diffusion">
                <div>
                    {props.film.channel}
                </div>
                <div>
                    {formatDay(props.film.start)} de <span>{formatTime(props.film.start)}</span> à <span>{formatTime(props.film.end)}</span>
                </div>
            </div>
        </div>
    </NavLink >
}