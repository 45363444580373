import { JukeboxCallbacks, JukeboxState } from "./JukeboxState";

export function JukeboxButtons(props: { state: JukeboxState, callbacks: JukeboxCallbacks }) {
    return (<div className="jukebox--buttons">
        <i className={
            ["bi", (props.state.playing) ? "bi-pause-circle" : "bi-play-circle"].join(" ")
        }
            id="jukebox-play"
            onMouseDown={() => props.callbacks.play()}></i>
        <i
            className="bi bi-fast-forward-circle"
            onMouseDown={() => props.callbacks.next()}></i>
    </div>)
}