import { NavLink } from "react-router-dom";
import { CategoryGroup } from "../../routes/RouteType";
import './PageHeader.scss';

export function PageHeader(props: { route: CategoryGroup }) {
    let route = props.route;
    if (route.header === undefined)
        return (<></>)
    return (
        <div className="header-menu">
            <div className="header-title">{route.header?.title}</div>
            <div className="header-buttons">
                {route.header.buttons !== undefined && route.header.buttons.map((button) => (
                    <NavLink
                        to={((button.uri !== undefined) ? button.uri : route.def_uri)}
                        className={[((button.disabled !== undefined && button.disabled) ? "disabled tooltip" : "")].join(" ")}
                        key={((button.uri !== undefined) ? button.uri : route.def_uri)} >
                        <span>{button.msg}</span>
                    </NavLink>
                ))}
            </div >

        </div >);
}