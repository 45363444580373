import { Route, Routes } from "react-router-dom";
import "./ContentPane.scss";
import Header from "./header/Header";
import { AppRoutes } from "../routes/AppRoutes";
import { PageHeader } from "./header/PageHeader";

function ContentPane() {
    return (
        <div className="content-pane">
            <Header />
            <Routes>
                {/** Make Page */}
                {AppRoutes.map((category) =>
                    category.pages.map((page) => (
                        <Route
                            path={page.uri}
                            element={
                                (<>
                                    {category.header !== undefined && (<PageHeader route={category} />)}
                                    {page.child != null && page.child()}
                                </>)
                            }
                            key={page.uri} />
                    ))
                )}
            </Routes>
        </div>
    )
}

export default ContentPane;