import './NavigationPane.scss'
import NavigationMenu from "./NavigationMenu";
import { Jukebox } from './jukebox/Jukebox';

function NavigationPane() {
    return (
        <div className="nav-section">
            <div className="nav-back"></div>
            <div className="nav-content">
                <NavigationMenu />
                <Jukebox />
            </div>
        </div>
    )
}

export default NavigationPane;